/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var home_scripts = {
    init: function() {
      $('.news_scroller').scroll(function() {
        if ((pos = this.scrollTop) <= 20) {
          $('.news_section_heading').css({
            'box-shadow': '0 ' + pos * 0.75 + 'px 15px -15px rgba(0,0,0,.2)',
          });
        }
      });
      $('.news_carousel').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
      });
      $('#jetmail_signup_button').click(function() {
        $('#jetmail_signup_modal').modal('show');
        return false;
      });
      $('#jetmail_signup_form').submit(function() {
        var form = this;

        if (!$(form).hasClass('loading')) {
          $(form).addClass('loading');
          $('#jetmail_signup_form_message')
            .hide()
            .removeClass('error')
            .removeClass('success')
            .html('');

          $.ajax({
            type: 'POST',
            url: wp.ajax_url,
            data: $(form).serialize(),
            datatype: 'json',
            success: function(data, textStatus, XMLHttpRequest) {
              $(form).removeClass('loading');

              if (data.status !== 'OK') {
                $('#jetmail_signup_form_message')
                  .addClass('error')
                  .html(data.message)
                  .show();
                return;
              }

              $('#jetmail_signup_form_message')
                .addClass('success')
                .html(data.message)
                .show();
              $('#jetmail_signup_form').hide();
              form.reset();
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              $(form).removeClass('loading');
              alert(errorThrown);
            },
          });
        }
        return false;
      });
      $('#jetmail_signup_modal').on('hidden.bs.modal', function() {
        $('#jetmail_signup_form_message')
          .hide()
          .removeClass('error')
          .removeClass('success')
          .html('');
        $('#jetmail_signup_form').show();
      });
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };

  var search_scripts = {
    init: function() {
      $(document).on('click', '.youtube_video_link', function() {
        var youtube_id = $(this).attr('data-video-id');
        var video_title = $(this).attr('data-video-title');
        var video =
          '<h2 class="h3">' +
          video_title +
          '</h2>' +
          '<div class="youtube_wrapper">' +
          '<iframe id="player" type="text/html" width="640" height="390" src="https://www.youtube.com/embed/' +
          youtube_id +
          '?autoplay=1&modestbranding=1&rel=0&showinfo=0&fs=1" frameborder="0" allowfullscreen="true"></iframe>' +
          '</div>';

        if (!$('#youtube_video_modal').length) {
          var html = '';
          html +=
            '<div id="youtube_video_modal" class="modal fade" tabindex="-1" role="dialog">' +
            '<div class="modal-dialog">' +
            '<div class="modal-content">' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>' +
            '<div class="modal-body">' +
            video +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
          $('body').append(html);
        } else {
          $('#youtube_video_modal .modal-body').html(video);
        }

        $('#youtube_video_modal').modal('show');
        $('#youtube_video_modal').on('hide.bs.modal', function() {
          $('#youtube_video_modal .modal-body').html('');
        });

        return false;
      });
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };

  var resource_scripts = {
    init: function() {
      // enable direct link to resources tab
      var url = document.location.toString();
      if (
        url.match('#') &&
        $(
          '#resource_tabs .nav-tabs a[href="#resource_tab_' +
            url.split('#')[1] +
            '"]'
        ).length
      ) {
        $(
          '#resource_tabs .nav-tabs a[href="#resource_tab_' +
            url.split('#')[1] +
            '"]'
        ).tab('show');
      }

      // update hash when switching resource tabs
      $('#resource_tabs .nav-tabs a').on('shown.bs.tab', function(e) {
        window.location.hash = e.target.hash.replace('resource_tab_', '');

        var tabLabel = $(this).text();

        // console.log('Resource Tab Selected: ', tabLabel);

        if (typeof ga === 'function') {
          ga('send', 'event', {
            eventCategory: 'Resource Tab Selected',
            eventAction: 'click',
            eventLabel: tabLabel,
          });
        }
      });
      // enable show all resource tabs
      $('#resource_tab_all').click(function() {
        $('#resource_tabs .nav-tabs li').removeClass('active');
        $('#resource_tabs #resource_tab_all').parent().addClass('active');
        $('#resource_tabs .tab-pane').each(function() {
          $(this).addClass('active');
        });
        window.location.hash = 'all';
      });
      // mobile expand/collapse resource tab filters
      $('#resource_tabs .nav-tabs').click(function() {
        $(this).toggleClass('closed');
      });
      // load more button
      $('.resource_load_more').click(function() {
        var $this = $(this);
        var resource_container = $this.closest('.row');
        var resource_type = $this
          .closest('.resource_section')
          .attr('id')
          .replace('resource_tab_', '');

        if (!$this.hasClass('loading')) {
          $this.addClass('loading');

          $.ajax({
            type: 'POST',
            url: wp.ajax_url,
            data: {
              action: 'load_resources',
              resource_type: resource_type,
            },
            datatype: 'html',
            success: function(data, textStatus, XMLHttpRequest) {
              $this.remove();
              resource_container.removeClass('has_more').html(data);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              $this.removeClass('loading');
              alert(errorThrown);
            },
          });
        }
        return false;
      });

      $('body.resources').on('click', '.resource .pdfc', function() {
        var resourceTitle = $(this).data('title');

        // console.log('Resource Opened: ', resourceTitle);

        if (typeof ga === 'function') {
          ga('send', 'event', {
            eventCategory: 'Resource Opened',
            eventAction: 'click',
            eventLabel: resourceTitle,
          });
        }
      });

      $('body.resources').on('click', '#pdfc_download', function(e) {
        // console.log('Resource Downloaded: ', e.target.href);

        if (typeof ga === 'function') {
          ga('send', 'event', {
            eventCategory: 'Resource Downloaded',
            eventAction: 'click',
            eventLabel: e.target.href,
            transport: 'beacon',
          });
        }
      });

      $('body.resources').on('click', '#pdfc_email', function(e) {
        // console.log('Resource Shared: ', e.target.href);

        if (typeof ga === 'function') {
          ga('send', 'event', {
            eventCategory: 'Resource Shared',
            eventAction: 'click',
            eventLabel: e.target.href,
            transport: 'beacon',
          });
        }
      });
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };

  var training_scripts = {
    init: function() {
      $(document).on('click', '.youtube_video_link', function() {
        var youtube_id = $(this).attr('data-video-id');
        var video_title = $(this).attr('data-video-title');
        var video =
          '<h2 class="h3" id="youtube-modal-title">' +
          video_title +
          '</h2>' +
          '<div class="youtube_wrapper">' +
          '<iframe id="player" type="text/html" width="640" height="390" src="https://www.youtube.com/embed/' +
          youtube_id +
          '?autoplay=1&modestbranding=1&rel=0&showinfo=0&fs=1" frameborder="0" allowfullscreen="true" aria-labelledby="youtube-modal-title"></iframe>' +
          '</div>';

        if (!$('#youtube_video_modal').length) {
          var html = '';
          html +=
            '<div id="youtube_video_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="youtube-modal-title">' +
            '<div class="modal-dialog">' +
            '<div class="modal-content">' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>' +
            '<div class="modal-body">' +
            video +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
          $('body').append(html);
        } else {
          $('#youtube_video_modal .modal-body').html(video);
        }

        $('#youtube_video_modal').modal('show');
        $('#youtube_video_modal').on('hide.bs.modal', function() {
          $('#youtube_video_modal .modal-body').html('');
        });

        return false;
      });

      // load more button
      $('.training_load_more').click(function() {
        var $this = $(this);
        var resource_container = $this.closest('.row');

        if (!$this.hasClass('loading')) {
          $this.addClass('loading');

          $.ajax({
            type: 'POST',
            url: wp.ajax_url,
            data: {
              action: 'load_training',
            },
            datatype: 'html',
            success: function(data, textStatus, XMLHttpRequest) {
              $this.remove();
              resource_container.removeClass('has_more').html(data);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              $this.removeClass('loading');
              alert(errorThrown);
            },
          });
        }
        return false;
      });
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };

  var news_scripts = {
    init: function() {
      // load more button
      $('.news_load_more').click(function() {
        var $this = $(this);
        var news_container = $this.closest('.row');

        if (!$this.hasClass('loading')) {
          $this.addClass('loading');

          $.ajax({
            type: 'POST',
            url: wp.ajax_url,
            data: {
              action: 'load_news',
            },
            datatype: 'html',
            success: function(data, textStatus, XMLHttpRequest) {
              $this.remove();
              news_container.removeClass('has_more').html(data);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              $this.removeClass('loading');
              alert(errorThrown);
            },
          });
        }
        return false;
      });
    },
    finalize: function() {},
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        $('a[href="#contact"], #contact_close').click(function() {
          $('#contact').slideToggle();
          return false;
        });

        $('.entry-content table').wrap('<div class="table_wrapper"/>');

        $('input[placeholder]').placeholder();

        $('a').click(function(e) {
          var url = new URL(e.target.href);

          if (typeof ga === 'function') {
            if (location.hostname !== url.hostname) {
              ga('send', 'event', {
                eventCategory: 'Outbound Link',
                eventAction: 'click',
                eventLabel: e.target.href,
                transport: 'beacon',
              });
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Search results page
    search: search_scripts,
    // Home page
    home: home_scripts,
    accueil: home_scripts,
    // Resources page
    resources: resource_scripts,
    ressources: resource_scripts,
    // Training page
    training: training_scripts,
    formation: training_scripts,
    // News Page
    news: news_scripts,
    nouvelles: news_scripts,
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, '_').split(/\s+/),
        function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        }
      );

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function create_message_modal(modal_options) {
  var number_of_modals = $('.modal_window').length;
  var oneTimeMessage = {
    modalWindow: function() {
      var window_height = $(window).height();
      var modal_height = $('#modal').outerHeight();
      var window_scroll_top = $(window).scrollTop();
      var modal = $(this.modal_window_selector);
      var top =
        Math.max(0, (window_height - modal_height) / 2 + window_scroll_top) +
        'px';
      var target_modal_trigger = $(this.modal_window_selector).prev(
        'a.modal-trigger'
      );
      var target_close_btn = modal.find('.modal_close_btn');
      var target_close_btn_class = target_close_btn.attr('class');

      if (number_of_modals == 1) {
        $('a[rel*=leanModal]').leanModal({
          closeButton: target_close_btn,
        });
      } else {
        if (modal.hasClass('first')) {
          $('a[href=' + this.modal_window_selector + ']').leanModal({
            closeButton: target_close_btn,
          });
        } else {
          $('a[href=' + this.modal_window_selector + ']').leanModal({
            closeButton: 'dummy_btn',
          });
          target_close_btn.click(function() {
            modal.css('display', 'none');
          });
        }
      }

      if (target_modal_trigger && target_modal_trigger != undefined) {
        target_modal_trigger.trigger('click');
      }

      $(this.modal_window_selector).css('top', top);
    },
    displayCornerMessage: function() {
      // Saving refrence of this in that to use it later
      var that = this;

      $('#close_announcement_btn').click(function() {
        that.hidingShowingDiv('.corner_modal');

        $('.corner_modal--button').click(function() {
          that.hidingShowingDiv('.message-box');
        });

        var announcement_id = $(this).parent().has('#announcement_id').length ?
          $(this).parent().find('#announcement_id').val() :
          false;
        toggleAgentSeenAnnouncement(announcement_id);
      });
    },
    hidingShowingDiv: function(element) {
      // this method is used for hiding and showing corner message window
      var target = $(element);

      if ($(target).css('display') === 'none') {
        $(target).css('display', 'block');
      } else {
        $(target).css('display', 'none');
      }
    },
    init: function(modal_options) {
      this.modal_window_selector = '#modal-window';
      this.corner_modal = false;
      this.first = false;

      if (modal_options) {
        var modal_window_selector = modal_options['modal_window_selector'];
        var corner_modal = modal_options['corner_modal'];

        if (
          modal_window_selector &&
          modal_window_selector != undefined &&
          modal_window_selector != ''
        ) {
          this.modal_window_selector = modal_window_selector;
        }

        if (corner_modal && corner_modal != undefined && corner_modal != '') {
          this.corner_modal = corner_modal;
        }
      }

      var first = $('.modal_window.first').length;

      if (!first) {
        $(this.modal_window_selector).addClass('first');
      }

      this.modalWindow();

      if (this.corner_modal) {
        this.displayCornerMessage();
      }
    },
  };

  oneTimeMessage.init(modal_options);

  return oneTimeMessage;
}

function toggleAgentSeenAnnouncement(announcement_id) {
  announcement_id =
    typeof announcement_id === 'undefined' ? false : announcement_id;
  var url = '/';

  if (wp.ajax_url) {
    url = wp.ajax_url;
  }
  var data = {
    action: 'ajax_toggle_agent_seen_announcement',
  };
  if (announcement_id) {
    data.announcement_id = announcement_id;
  }
  $.post(url, data, function(response, status, request) {
    if (response) {
      if (response.success) {
        // if (callback != undefined) {
        //     callback(response);
        // }
      }
    }
  });
}
