// these functions and methods will be avaliable globally

(function($) {
  var globals = {
    creatingDropDown: function() {
      // this method is responsible for dropdown on signup page and my account page
      $('.dropdown').select2();
    },
    showPasswordOnClick: function() {
      // this method is used for show and hiding password button
      $('.s-show-password-btn').click(function() {
        $(this).prev().prev().attr('type', 'text');
        $(this).css('display', 'none');
        $(this).prev().css('display', 'inline-block');
      });
    },
    hidePasswordOnClick: function() {
      // this method is used for show and hiding password button
      $('.h-hide-password-button').click(function() {
        $(this).prev().attr('type', 'password');
        $(this).css('display', 'none');
        $(this).next().css('display', 'inline-block');
      });
    },
    goBackButton: function() {
      // this method is used for going back to previous step on signup page
      $('.c-signup__btns--back').click(function(e) {
        e.preventDefault();

        // target is what to want to show
        // itself if container which you want to hide
        var target = '.' + $(this).attr('data-target');
        var itself = '.' + $(this).attr('data-this');
        $(itself).css('display', 'none');
        $(target).css('display', 'block');
      });
    },
    goNextButton: function() {
      // this method is used for going to next step on signup page
      $('.js-change-form').click(function(e) {
        e.preventDefault();

        // target is what to want to show
        // itself if container which you want to hide
        var target = '.' + $(this).attr('data-target');
        var itself = '.' + $(this).attr('data-this');
        $(itself).css('display', 'none');
        $(target).css('display', 'block');
      });
    },
    submitFormOnEnter: function() {
      $('input').keypress(function(e) {
        if (e.which === 13) {
          $(this).closest('form').submit();
        }
      });
    },
    init: function() {
      this.creatingDropDown();
      this.goNextButton();
      this.goBackButton();
      this.showPasswordOnClick();
      this.hidePasswordOnClick();
      this.submitFormOnEnter();
    },
  };

  globals.init();
})(jQuery);

function calculate_element_center(form_el, loader_el, loader_el_type) {
  var logging = false;

  if (form_el && loader_el) {
    var result = { x: 0, y: 0 };
    var default_width = 33;
    var defaut_height = 33;

    if (loader_el_type == 'large') {
      default_width = 100;
      defaut_height = 100;
    }

    var width = loader_el.outerWidth();
    var height = loader_el.outerHeight();

    if (width == 0) {
      width = default_width;
    }

    if (height == 0) {
      height = defaut_height;
    }

    var active_form = undefined;

    if (form_el != undefined) {
      active_form = form_el;
    }

    if (active_form != undefined) {
      var active_form_position = active_form.offset();

      var left = 0;
      var top = 0;

      if (active_form_position != undefined) {
        left = active_form_position.left;
        top = active_form_position.top;
      }

      var active_form_width = active_form.outerWidth();
      var active_form_height = active_form.outerHeight();

      var centerX = left + active_form_width / 2 - width / 2;
      var centerY = top + active_form_height / 2 - height / 2;

      result.x = centerX;
      result.y = centerY;

      if (logging) {
        console.log('\n*****************');
        console.log('Form container');
        console.log(
          'Position',
          active_form_position,
          '(' + active_form_width + ',' + active_form_height + ')'
        );
        console.log('----------------');
        console.log('Animation wrapper');
        console.log('(' + width + ',' + height + ')');
        console.log('Center', centerX, centerY);
        console.log('*****************\n');
      }
    }

    return result;
  } else {
    return false;
  }
}

function toggle_loader_with_form(loader_el, form, show) {
  var logging = true;
  var class_to_toggle = 'hidden';
  var loader_el_type = 'regular';

  $('.to_revert_btn').css('color', '').removeClass('to_revert_btn');

  if (show) {
    var submit_btn = $(form).find('input[type="submit"]');

    if (submit_btn.length == 0) {
      submit_btn = $(form).find('.btn--navyblue:visible');

      if (submit_btn.length == 0) {
        submit_btn = $(form).find('a.subscribe_link');

        if (submit_btn.length == 0 && $(form).hasClass('logo__image')) {
          submit_btn = form;
        }
      }
    }

    if (submit_btn.length == 1) {
      if (
        submit_btn.hasClass('subscribe_link') ||
        submit_btn.hasClass('remove_agency_logo_btn')
      ) {
        loader_el.addClass('subscribe_links');
      } else {
        submit_btn.addClass('to_revert_btn').css('color', 'transparent');
      }

      // }
      loader_el.removeClass('large').removeClass('subscribe_links');
    } else if (submit_btn.length > 1) {
      loader_el_type = 'large';
      loader_el.css('left', '').css('top', '');
      loader_el.addClass('large').removeClass('hidden');
      return;
    }

    var centerX = 0;
    var centerY = 0;
    var center = calculate_element_center(
      submit_btn,
      loader_el,
      loader_el_type
    );

    if (center && center != undefined) {
      var temp_centerX = center.x;
      var temp_centerY = center.y;

      if (temp_centerX && temp_centerX != undefined) {
        centerX = temp_centerX;
      }

      if (temp_centerY && temp_centerY != undefined) {
        centerY = temp_centerY;
      }
    }

    loader_el.css({ left: centerX, top: centerY });
    loader_el.removeClass(class_to_toggle);
  } else {
    // submit_btn.css("color", "");
    loader_el.addClass(class_to_toggle);
  }
}
