(function($) {
  var active_lang = '';

  if (wp.active_lang) {
    active_lang = wp.active_lang;
  }

  var myaccount_functionality = {
    mobileDropDown: function() {
      var nav = $('.mobile_trigger');
      var menus = $('.mobile_trigger').children();

      $(menus).click(function() {
        if ($(menus).hasClass('not-visible')) {
          $(menus).removeClass('not-visible').removeClass('open');
        } else {
          $(menus).addClass('not-visible').removeClass('open active');
          $(this).removeClass('not-visible').addClass('open active');
        }
        $(nav).prepend(this);
      });
    },
    desktopDropdown: function() {
      var navItems = $('.desktop_trigger li');

      $(navItems).click(function() {
        var target = '.' + $(this).attr('data-target');

        $('.js-profile-main').css('display', 'none');

        $(navItems).removeClass('open').removeClass('active');
        $(this).addClass('open').addClass('active');
        $(target).css('display', 'block');
      });
    },
    showingMainContent: function() {
      var mainArea = $('.js-profile-main');

      $('.pages li').click(function() {
        var target = $(this).attr('data-target');

        $(mainArea).css('display', 'none');

        $('.' + target).css('display', 'block');
      });
    },
    makingFormsEditable: function() {
      $('.userinfo__enable-input').click(function() {
        var field = $(this).closest('.userinfo__title').next().find('input');

        // removing disabled attribute and adding enabled
        $(field).removeAttr('disabled').attr('enabled', 'true').focus();

        // Hide this image and showing close field image
        $(this).css('display', 'none').prev().css('display', 'inline-block');
      });
    },
    makingFormsNonEditable: function() {
      $('.userinfo__disable-input').click(function() {
        var field = $(this).closest('.userinfo__title').next().find('input');

        // removing disabled attribute and adding enabled
        $(field).removeAttr('enabled').attr('disabled', 'true');

        // Hide this image and showing close field image
        $(this).css('display', 'none').next().css('display', 'inline-block');
      });
    },
    myAccountDropdown: function() {
      var target = $('.selected_value');

      var home_airport_label =
        active_lang == 'fr' ? 'Aéroport de votre ville' : 'Home airport';

      $('.userinfo__disable-enable-airport').click(function() {
        $('.userinfo__input-airport-dropdown').css('display', 'block');
        $('.selected_value').text(home_airport_label);
        $(this).css('display', 'none').prev().css('display', 'inline-block');
      });

      $('.userinfo__disable-input-airport').click(function() {
        $('.selected_value').text($('.label').text());
        $('.userinfo__input-airport-dropdown').css('display', 'none');
      });
    },
    replacingHomeAirportPosition: function() {
      function detectingWidth() {
        var wid = $(window).width();
        var el = $('.home_airport');
        var desktop = $('.c-profile__userinfo-desktop');
        var mobile = $('.c-profile__userinfo-mobile');

        if (wid <= 768) {
          mobile.append(el);
        } else {
          desktop.append(el);
        }
      }

      $(window).resize(detectingWidth);
      $(document).ready(detectingWidth);
    },
    showingHidingAdditionalAdressFields: function() {
      $('.show_additional_fields').click(function() {
        $('.userinfo__addressfields--hidden')
          .addClass('show')
          .find('input')
          .removeAttr('disabled')
          .attr('enabled', 'true');
        $('.address_title').css('display', 'none');
      });

      $('.hide_additional_fields').click(function() {
        $('.userinfo__addressfields--hidden')
          .removeClass('show')
          .find('input')
          .removeAttr('enabled')
          .attr('disabled', 'true');
        $('.address_title').css('display', 'block');
      });
    },
    unSubscribe: function() {
      $('.unsubscribe').click(function() {
        $(this).css('display', 'none').next().css('display', 'inline-block');
      });

      $('.subscribe').click(function() {
        $(this).css('display', 'none').prev().css('display', 'inline-block');
      });
    },
    creatingDropDown: function() {
      // this method is responsible for dropdown on signup page and my account page
      $('.dropdown').select2({
        theme: 'classic',
        // placeholder: 'Select a state'
      });
    },
    displayHomeAirportDefaultValue: function() {
      var home_airport_label =
        active_lang == 'fr' ? 'Aéroport de votre ville' : 'Home airport';

      $('.userinfo__disable-enable-airport').click(function() {
        $('.selected_value').text(home_airport_label);
      });

      $('.userinfo__disable-input-airport').click(function() {
        var airport = $('#select2-home_airport_select-container').text();

        $('.selected_value').text(airport);
      });
    },
    validation: function() {
      // Method for password validation
      $.validator.addMethod('strongPassword', function(value, element) {
        var conditions = $('.c-form__signup-conditions').children();

        var min_length = 4;
        var max_length = 20;

        var length = value.length >= min_length && value.length <= max_length;

        if (length) {
          $(conditions[0]).addClass('valid');
        } else {
          $(conditions[0]).removeClass('valid');
        }

        // return length && lowercaseRegex && uppercaseRegex && specialCharacter;
        return length;
        // }, 'Please enter a valid password');
      });

      //email validation regex
      $.validator.addMethod(
        'emailValidate',
        function(value, element) {
          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(value);
        },
        'Please enter a valid email'
      );

      $.validator.addMethod('addressValidateAlpha', function(value, element) {
        var re = /^[a-zA-Z-\s]*$/;

        return re.test(value);
      });

      $.validator.addMethod(
        'addressValidateAlphanumeric',
        function(value, element) {
          var re = /^[a-zA-Z0-9-\s]*$/;

          return re.test(value);
        }
      );

      // Add US Phone Validation
      jQuery.validator.addMethod(
        'phoneUS',
        function(phone_number, element) {
          phone_number = phone_number.replace(/\s+/g, '');
          return (
            this.optional(element) ||
            (phone_number.length > 9 &&
              phone_number.match(
                /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/
              ))
          );
        },
        ''
      );

      var lang = $('html').attr('lang');

      if (lang === 'fr-FR') {
        jQuery.extend(jQuery.validator.messages, {
          required: 'Ceci est un champ obligatoire',
          emailValidate: 'Veuillez entrer une adresse courriel valide',
          phoneUS: 'Please enter valid phone number[fr]',
          minlength: 'Numéro invalide',
          number: 'Format de numéro non valide',
          maxlength: 'Numéro invalide',
          strongPassword: 'Veuillez entrer un mot de passe valide',
          phoneUS: 'S\'il vous plait entrez un numéro de téléphone valide',
          WestjetRewards: 'Veuillez entrer un code WestJet Rewards valide',
          contactName: 'Veuillez entrer un nom de contact',
          agencyName: 'Veuillez saisir le nom de votre agence',
          iataNumber: 'Veuillez entrer un numéro IATA / ARC / TIDS valide',
          addressValidateAlpha:
            'Veuillez entrer les caractères alphanumériques seulement',
          addressValidateAlphanumeric:
            'Veuillez entrer les caractères alphanumériques seulement',
          addressValidateStreet:
            'Veuillez entrer une adresse municipale valide',
          addressValidateCity: 'Veuillez entrer une ville valide',
          addressValidateProvince: 'Veuillez entrer une province valide',
          addressValidateCountry: 'Veuillez entrer un pays valide',
          addressValidatePostalCode: 'Veuillez entrer un code postal valide',
        });
      } else {
        jQuery.extend(jQuery.validator.messages, {
          required: 'This field is required.',
          emailValidate: 'Please enter a valid email address',
          phoneUS: 'Please enter valid phone number',
          minlength: 'Invalid number',
          maxlength: 'Invalid number',
          number: 'Please enter a valid number.',
          strongPassword: 'Please enter your password',
          phoneUS: 'Please enter valid phone number',
          WestjetRewards: 'Please enter a valid WestJet Rewards ID',
          contactName: 'Please enter a contact name',
          agencyName: 'Please enter your agency name',
          iataNumber: 'Please enter a valid IATA/ARC/TIDS number',
          addressValidateAlpha: 'Please enter only alpha characters',
          addressValidateAlphanumeric:
            'Please enter only alphanumeric characters',
          addressValidateStreet: 'Please enter a valid street address',
          addressValidateCity: 'Please enter a valid city',
          addressValidateProvince: 'Please enter a valid province',
          addressValidateCountry: 'Please enter a valid country',
          addressValidatePostalCode: 'Please enter a valid postal code',
        });
      }

      // adding custom methods for forms validation
      $.validator.setDefaults({
        errorPlacement: function(error, element) {
          if ($(element).attr('id') === 'new_pass') {
            $(element).parent().append(error);
          } else {
            $(error).insertAfter(element);
          }
        },
      });

      // validating reset password email input
      $('.c-profile__userinfo form').validate({
        onkeyup: function(element) {
          if ($(element).valid()) {
            $(element).removeAttr('style');
            $(element).removeClass('has_errors');
          } else {
            $(element).css('border', '1px solid #d61717');
            $(element).addClass('has_errors');
          }
          // trigger_errors($(element), $(element).valid());
        },
        ignore: '.ignore',
        rules: {
          agency_name: {
            required: true,
          },
          email_address: {
            required: true,
            emailValidate: true,
          },
          iata_number: {
            required: true,
            number: true,
            minlength: 8,
          },
          contact_number: {
            phoneUS: true,
          },
          contact_name: {
            required: true,
          },
          westjet_rewards_number: {
            number: true,
            minlength: 9,
            maxlength: 9,
          },
          street_address: {
            addressValidateAlphanumeric: true,
          },
          city: {
            addressValidateAlpha: true,
          },
          province: {
            addressValidateAlpha: true,
          },
          country: {
            addressValidateAlpha: true,
          },
          zip_code: {
            addressValidateAlphanumeric: true,
          },
        },
        messages: {
          email_address: {
            required: jQuery.validator.messages.emailValidate,
            emailValidate: jQuery.validator.messages.emailValidate,
          },
          agency_name: {
            required: jQuery.validator.messages.agencyName,
          },
          iata_number: {
            required: jQuery.validator.messages.iataNumber,
            number: jQuery.validator.messages.iataNumber,
            minlength: jQuery.validator.messages.iataNumber,
          },
          contact_name: {
            required: jQuery.validator.messages.contactName,
          },
          westjet_rewards_number: {
            number: jQuery.validator.messages.WestjetRewards,
            minlength: jQuery.validator.messages.WestjetRewards,
            maxlength: jQuery.validator.messages.WestjetRewards,
          },
          street_address: {
            addressValidateAlphanumeric:
              jQuery.validator.messages.addressValidateStreet,
          },
          city: {
            addressValidateAlpha: jQuery.validator.messages.addressValidateCity,
          },
          province: {
            addressValidateAlpha:
              jQuery.validator.messages.addressValidateProvince,
          },
          country: {
            addressValidateAlpha:
              jQuery.validator.messages.addressValidateCountry,
          },
          zip_code: {
            addressValidateAlphanumeric:
              jQuery.validator.messages.addressValidatePostalCode,
          },
        },
        submitHandler: function(form) {
          myaccount_toggle_submit_btn_and_loader(form, true);
          form.submit();
        },
      });

      $('.c-profile-reset-password form').validate({
        onkeyup: function(element) {
          if ($(element).valid()) {
            $(element).removeClass('border--red');
            // $(element).parent().find('p.error').css('display', 'none');
            $(element).removeClass('has_errors');
          } else {
            $(element).addClass('border--red');
            $(element).addClass('has_errors');
          }
        },
        highlight: function(element, errorClass) {
          $(element).removeClass('error');
        },
        rules: {
          new_pass: {
            strongPassword: true,
          },
        },
        messages: {
          new_pass: {
            strongPassword: jQuery.validator.messages.strongPassword,
          },
        },
        submitHandler: function(form) {
          var details = {
            new_pass: $('#new_pass').val(),
            current_pass: $('#current_pass').val(),
          };
          myaccount_toggle_submit_btn_and_loader(form, true);
          verifyCurrentPassword(details, form);
          // form.submit();
        },
      });
    },
    init: function() {
      this.mobileDropDown();
      this.desktopDropdown();
      this.showingMainContent();
      this.makingFormsEditable();
      this.myAccountDropdown();
      this.creatingDropDown();
      this.unSubscribe();
      this.validation();
      this.makingFormsNonEditable();
      this.showingHidingAdditionalAdressFields();
      //this.replacingHomeAirportPosition();
      this.displayHomeAirportDefaultValue();
    },
  };

  myaccount_functionality.init();

  // setting home airport default value
  var airport = $('#select2-home_airport_select-container').text();
  $('.selected_value').text(airport);

  //submitting form when user click save button
  $('.save_form_btn').click(function(e) {
    $('#update_account_details_form').submit();
  });

  // validating IATA number before validating
  $('.IATA_number .ignore').click(function() {
    if ($('.IATA_number input').valid()) {
      var details = {
        iata_number: $('.IATA_number input').val(),
      };

      var response = verifyAgency(details);
    }
  });

  // Ajax calls
  var url = '/';
  if (wp.ajax_url) {
    url = wp.ajax_url;
  }

  $('#edit_logo_btn').click(function() {
    var data_target = $(this).attr('data-action');

    if (data_target == 'file_upload') {
      $('#new_logo_upload').trigger('click');
    } else if (data_target == 'update_logo') {
      myaccount_toggle_submit_btn_and_loader(
        $('#update_agency_logo_form'),
        true
      );
      $('#update_agency_logo_form').submit();
    }
  });

  $('#new_logo_upload:file').change(function() {
    var file_name = $(this).val();
    var valid_file_types = ['image/gif', 'image/jpeg', 'image/png'];
    var max_file_size_mb = 2;
    var max_file_size = 1024 * 1024 * max_file_size_mb; // 2MB
    var errors = false;
    var error_messages = {
      invalid_file_type: 'Invalid file type\nFile must be PNG or JPG.',
      file_too_large:
        'File too large\nPlease provide an image less than ' +
        max_file_size_mb +
        'MB',
      corrupt_file: 'Corrupt File',
      invalid_file_type_fr:
        'Fichier non valide\nLe fichier doit être en format PNG ou JPG',
      file_too_large_fr:
        'Fichier trop large\nVeuillez fournir une image inférieure à ' +
        max_file_size_mb +
        'MB',
      corrupt_file_fr: 'Fichier corrompu',
    };

    var active_lang = '';

    if (wp.active_lang) {
      if (wp.active_lang == 'fr') {
        active_lang = '_fr';
      }
    }

    var error_msg = '';

    if (this.files.length > 0) {
      var file = this.files[0];
      var name = file['name'];

      var file_type = file['type'];
      var file_size = file['size'];

      var upload_text = 'Upload Logo';
      upload_text = $('#edit_logo_btn').data('upload-text');

      if (file != undefined) {
        if ($.inArray(file_type, valid_file_types) < 0) {
          errors = true;
          error_msg = error_messages['invalid_file_type' + active_lang];
        } else {
          if (file_size > max_file_size) {
            errors = true;
            error_msg = error_messages['file_too_large' + active_lang];
          } else {
            var img_preview = window.URL.createObjectURL(file);
            var target_logo = $(
              '.c-profile__logo .logo__image div.img-responsive'
            );
            // var old_src = target_logo.attr('src');
            var old_src = get_background_image_src(target_logo);

            target_logo.attr('data-old-src', old_src);
            target_logo.attr('data-new-src', img_preview);
            set_image_as_background_image(target_logo, img_preview);

            $('#edit_logo_btn').attr('data-action', 'update_logo');
            $('#edit_logo_btn').text(upload_text);

            switch_remove_agency_logo_btn(true);
          }
        }
      } else {
        errors = true;
        error_msg = error_messages['corrupt_file' + active_lang];
      }
    }

    if (errors) {
      if (error_msg != undefined) {
        alert(error_msg);
      }
    }
  });

  function verifyCurrentPassword(details, reset_password_form) {
    var data = { action: 'ajax_verify_current_password' };
    var data = $.extend(data, details);

    $.post(url, data, function(response, status, request) {
      if (response.success) {
        $('#current_pass').css('border-color', '#cecece');

        var new_pass_details = { new_pass: $('#new_pass').val() };
        var update_password_details = $.extend(details, new_pass_details);

        if (reset_password_form != undefined) {
          reset_password_form.submit();
          // resetPassword(reset_password_form);
        }
      } else {
        myaccount_toggle_submit_btn_and_loader(reset_password_form, false);
        // var error_message = response.message;
        // var error_el = '<p class="error">' + error_message + '</p>';
        $('#current_pass').addClass('has_errors');
      }
    });
  }

  function resetPassword(reset_password_form) {
    var data = { action: 'update_user_password' };

    var details = reset_password_form.serialize();
    var data = $.extend(data, details);

    $.post(url, data, function(response, status, request) {
      if (response.success) {
        $('#current_pass').css('border-color', '#cecece');

        var new_pass_details = { new_pass: $('#new_pass').val() };
        var update_password_details = $.extend(details, new_pass_details);

        if (reset_password_form != undefined) {
          reset_password_form.submit();
        }
      } else {
      }
    });
  }

  function verifyAgency(details) {
    var data = { action: 'verify_agency' };
    var data = $.extend(data, details);
    var res;
    $.post(url, data, function(response, status, request) {
      res = response;

      if (response.success) {
        $('#update_account_details_form').submit();
      } else {
        var lang = $('html').attr('lang');

        if (lang === 'en-US') {
          $('#iata_number-error')
            .css('display', 'inline-block')
            .text('Invalid IATA/ARC/TIDS number');
        } else {
          $('#iata_number-error')
            .css('display', 'inline-block')
            .text('Numéro IATA / ARC / TIDS non valide');
        }
      }
    });

    return;
  }

  function fix_remove_logo_btn() {
    var width = $('.agency_logo').width();
    var height = $('.agency_logo').height();

    $('.remove_logo_btn').width(width);
    $('.remove_logo_btn').height(height);
    $('.remove_logo_btn').addClass('test');
  }

  function switch_remove_agency_logo_btn(upload) {
    if (upload) {
      var upload_action = $('.remove_agency_logo_btn').data('upload-action');
      $('.remove_agency_logo_btn').attr('data-target-action', upload_action);
    } else {
      var remove_action = $('.remove_agency_logo_btn').data('remove-action');
      $('.remove_agency_logo_btn').attr('data-target-action', remove_action);
    }
  }

  function remove_current_agency_logo() {
    var target_logo = $('.c-profile__logo .logo__image div.img-responsive');
    var old_src = target_logo.data('old-src');
    var new_action = 'file_upload';

    var edit_logo_btn = $('#edit_logo_btn');
    var edit_text = edit_logo_btn.data('edit-text');

    // target_logo.attr("src", old_src);

    set_image_as_background_image(target_logo, old_src);
    // target_logo.attr("old-src", "");

    edit_logo_btn.attr('data-action', new_action);
    edit_logo_btn.text(edit_text);

    $('#new_logo_upload').val('');
  }

  $('.remove_agency_logo_btn').click(function() {
    var target_action = $(this).data('target-action');

    if (target_action == 'remove_agency_logo') {
      myaccount_toggle_submit_btn_and_loader(
        $('#update_agency_logo_form .logo__image'),
        true
      );
      remove_agency_logo();
    } else if (target_action == 'remove_current_agency_logo') {
      remove_current_agency_logo();
      switch_remove_agency_logo_btn(false);
    }
  });

  function remove_agency_logo() {
    var data = { action: 'remove_agency_logo' };

    $.post(url, data, function(response, status, request) {
      myaccount_toggle_submit_btn_and_loader(
        $('#update_agency_logo_form .logo__image'),
        false
      );

      if (response.success) {
        var success_message = $('.remove_agency_logo_btn').data(
          'delete-success-text'
        );

        if (success_message && success_message != undefined) {
          alert(success_message);
        }
        location.reload();
      } else {
      }
    });
  }

  function set_image_as_background_image(el, image) {
    if (el != undefined && el) {
      if (image != undefined && image) {
        var image_url = 'url(\'' + image + '\')';
        el.css('background-image', '');
        el.css('background-image', image_url);
      }
    }
  }

  function get_background_image_src(el) {
    var bg_img = el.css('background-image');
    var to_replace = ['url("', '")'];

    if (bg_img && bg_img != undefined && bg_img != '') {
      for (var i = 0; i < to_replace.length; i++) {
        var current_rule = to_replace[i];
        var tmp_bg_img = bg_img.replace(current_rule, '');
        bg_img = tmp_bg_img;
      }
    }

    return bg_img;
  }

  function trigger_errors(element, valid) {
    if (element) {
      var clear = false;
      var value = $(element).val();

      if (valid) {
        element.removeClass('has_errors');
        // element.parent().find('p.error.show').removeClass("show");
        element.parent().find('label.error.show').removeClass('show');
      } else {
        if (value == '' && clear) {
          element.parent().find('label.error.show').removeClass('show');
        } else {
          element.addClass('has_errors');
        }
      }
    }
  }

  function show_password_update_sucess_popup() {
    var modal_options = {
      modal_window_selector: '#modal-window-password-update-success',
      corner_modal: false,
    };

    var oneTimeMessage = create_message_modal(modal_options);
  }

  function myaccount_toggle_submit_btn_and_loader(form, show) {
    toggle_loader_with_form($('.loader_wrapper'), form, show);
  }
})(jQuery);
